<template>
  <div class="p-3 listview card border-light">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-4">
        <div class="cell">
          <label>{{ $t('name') }}: </label>
          <span>{{ value.name }} ({{ value.id }})</span>
        </div>
        <div class="cell">
          <label>Application Id: </label>
          <span>{{ value.clientId}}</span>
        </div>
        <div class="cell">
          <label>{{ $t('active') }}: </label>
          <span>{{ $t(value.isActive ? 'yes' : 'no') }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-4">
        <div class="cell">
          <label>Tenant Id: </label>
          <span>{{ value.tenantId }}</span>
        </div>
        <div class="cell">
          <label>RedirectUri: </label>
          <span>{{ value.redirectUri }}</span>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-4">
        <action-button :actions="actions" :text="$t('actions')" />
      </div>
    </div>
  </div>
</template>
<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'AuthClientSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('edit'),
          iconClass: 'far fa-edit',
          iconColor: '#105AEE',
          action: () => this.$emit('edit'),
        }
      ],
    };
  }
};
</script>
<style lang="scss" scoped>
.listview .cell {
  clear: left;
}
.listview .cell label {
  float: left;
  font-weight: bold;
}
.listview .cell span,
::v-deep .formatted-text label {
  margin-left: 5px;
  color: $color-primary;
  font-weight: bold;
}
.card {
  word-break: break-word;
}
</style>

