<template>
  <validation-observer ref="formRef" novalidate>
    <div class="row">
      <div class="col-sm-12">
        <div class="card" style="border: white">
          <div class="row">
            <div class="col-md-12 col-lg-6">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>{{ $t('name') }}:</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:256"
                >
                  <base-input
                    v-model="model.name"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>Authority:</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:128"
                >
                  <base-input
                    v-model="model.authority"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>ClientId:</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:36"
                >
                  <base-input
                    v-model="model.clientId"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>Client Secret:</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:100"
                >
                  <base-input
                    v-model="model.clientSecret"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>TenantId:</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:36"
                >
                  <base-input
                    v-model="model.tenantId"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>RedirectUri:</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required|max:256"
                >
                  <base-input
                    v-model="model.redirectUri"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <div class="form-group">
                <div class="d-flex justify-content-between">
                  <label>PostLogoutRedirectUri:</label>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  rules="max:256"
                >
                  <base-input
                    v-model="model.postLogoutRedirectUri"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                  />
                </validation-provider>
              </div>
            </div>
            <div class="col-md-12 col-lg-6">
              <b-form-checkbox
                v-model="model.isActive"
                class="mt-4 chkMiddle mr-3"
                switch
              >
                {{ $t('active') }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 my-5 text-right">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <button
          class="btn btn-outline-primary"
          variant="outline-primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>
<script>
import BaseInput from '@/components/BaseInput';
import {
  createAuthClient as _createAuthClient,
  editAuthClient as _editAuthClient,
} from '@/services/AuthClientService';

export default {
  name: 'AuthClientForm',
  components: {
    BaseInput,
  },
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      model: {
        id: null,
        name: null,
        authority: null,
        clientId: null,
        clientSecret: null,
        tenantId: null,
        redirectUri: null,
        isActive: false,
        postLogoutRedirectUri: null
      },
    };
  },
  mounted() {
    this.model = { ...this.model, ...this.value };
  },
  methods: {
    async onSubmit() {
      if (!(await this.$refs.formRef.validate())) return;

      const request = !this.model.id
        ? _createAuthClient
        : _editAuthClient;
      request(this.model)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('close');
          this.$emit('submitted');
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
  .chkMiddle {
    margin-top: 36px!important;
  }
  .splitter {
    border: 1px solid lightgray;
    width: 100%;
    margin: 10px 0;
  }

  @media only screen and (min-width: 768px) {
    .col-sm-splitter {
      display: none;
    }
  }
</style>